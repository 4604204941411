/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../theme/theme-vars';
import RichText from '../../../elements/richText';

const DisclaimerWrapper = styled.section`
  background-color: ${theme.colours.darkGrey};
  text-align: center;
  padding: 50px 100px;

  @media (max-width: ${theme.screens.screenMedium}) {
    padding: 50px 10px;
  }
`;

const TitleText = styled.p`
  color: ${theme.colours.white};
`;
const DisclaimerText = styled.div`
  & p {
    color: ${theme.colours.white};
  }
  & a:hover {
    color: ${theme.colours.pink};
  }
`;

const Disclaimer = ({
  disclaimerData
}) => {
  const { title, components } = disclaimerData;
  const data = components[0].text;
  return (
    <DisclaimerWrapper>
      <TitleText>{title}</TitleText>
      <DisclaimerText><RichText text={data} /></DisclaimerText>
    </DisclaimerWrapper>
  );
};

export default Disclaimer;
