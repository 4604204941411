/* eslint-disable block-scoped-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';
import { theme } from '../../../theme/theme-vars';
import useWindowDimensions from '../../../utilities/windowDimension';
import instagramIcon from '../../../../static/images/instagram-icon-red.png';
import facebookIcon from '../../../../static/images/facebook-icon-red.png';
import winBanner from '../../../../static/images/win-banner.png';

const CtaWrapper = styled.section`
  display: flex;

  @media (max-width: ${theme.breakpoints.max.lg}) {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  max-width: 50vw;
  padding: 100px 50px 100px 0;

  .mb-0 {
    margin-bottom: 0 !important;
  }

  @media (max-width: ${theme.breakpoints.max.xl}) {
    max-width: 40vw;
    padding: 50px 30px 50px 0;
  }

  @media (max-width: ${theme.breakpoints.max.lg}) {
    max-width: 100vw;
    padding: 50px 20px;
  }
`;

const Heading = styled.h2`
  text-align: left !important;
  font-family: ${theme.font.nimbleFont};
  font-weight: bold;
  font-style: normal;
  font-size: 28px;
`;

const Copy = styled.div`
  text-align: left;
  font-weight: ${props => (props.bold ? 'bold' : null)};
`;

const SocialWrapper = styled.div`
  display: flex;
  margin-top: 30px;
`;

const Icon = styled.img`
  width: 35px;
  margin-right: 15px;
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  min-width: 50vw;
  position: relative;
  display: flex;
  justify-content: center;

  @media (max-width: ${theme.breakpoints.max.xl}) {
    min-width: 60vw;
  }

  @media (max-width: ${theme.breakpoints.max.lg}) {
    max-width: 100vw;
    overflow: inherit;
    overflow: hidden;
  }
`;

const SideImg = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  @media (max-width: ${theme.breakpoints.max.lg}) {
    min-width: 100%;
    width: 100%;
    position: inherit;
  }
`;

const Banner = styled.img`
  width: 320px;
  position: absolute;
  top: -100px;
  right: -100px;

  @media (max-width: ${theme.breakpoints.max.lg}) {
    display: none;
  }
`;

const ApplyButton = styled.a`
    box-sizing: border-box;
`;

const Cta = ({ bannerData }) => {
  const {
    applyNowLink,
    description,
    subTitle,
    longDescription,
    desktopBackgroundImage
  } = bannerData;
  // Dynamic Padding
  const [paddingLeft, setPaddingLeft] = useState(20);
  const { width } = useWindowDimensions();
  const desktopWidthMin = 1420;
  const paddingLeftStyle = {
    paddingLeft: `${paddingLeft}px`
  };
  useEffect(() => {
    const contentMaxWidth = 1440;
    if (width <= desktopWidthMin) {
      setPaddingLeft(20);
    } else {
      var leftPadding = Math.max(0, (width - contentMaxWidth) / 2 + 25);
    }
    setPaddingLeft(leftPadding);
  }, [width]);

  return (
    <CtaWrapper>
      <ContentWrapper style={paddingLeftStyle}>
        <Heading>{subTitle}</Heading>
        <Copy>
          <b>{description}</b>
        </Copy>
        <Copy>
          <ReactMarkdown>
            {longDescription.internal.content}
          </ReactMarkdown>
        </Copy>
        <ApplyButton
          href={applyNowLink}
          className="component-button button-color_Green mb-0"
        >
          Apply Now
        </ApplyButton>
        <SocialWrapper>
          <a href="https://www.instagram.com/nimbleaustralia/?hl=en">
            <Icon src={instagramIcon} />
          </a>
          <a href="https://www.facebook.com/NimbleAustralia/">
            <Icon src={facebookIcon} />
          </a>
        </SocialWrapper>
      </ContentWrapper>
      <ImageWrapper>
        <SideImg image={desktopBackgroundImage.gatsbyImageData} alt="side image" />
        <Banner src={winBanner} />
      </ImageWrapper>
    </CtaWrapper>
  );
};

export default Cta;
