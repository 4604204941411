/* eslint-disable react/prop-types */
import React from 'react';
import Hero from './hero';
import Cta from './cta';
import Disclaimer from './disclaimer';

const RioGiveaway = ({ componentData }) => {
  const bannerData = componentData.filter(
    data => data.__typename === 'ContentfulNimblePageHeadingSection'
  )[0];
  const disclaimerData = componentData.filter(
    data => data.sectionId === 'Win Page Disclaimer'
  )[0];
  const { title } = bannerData;
  return (
    <>
      <Hero title={title} />
      <Cta bannerData={bannerData} />
      {disclaimerData && <Disclaimer disclaimerData={disclaimerData} />}
    </>
  );
};

export default RioGiveaway;
