import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from '../../../theme/theme-vars';
import useWindowDimensions from '../../../utilities/windowDimension';
import Rabbit from '../../../../static/images/white-rabbit-10op.png';

const HeroWrapper = styled.section`
  background-color: ${theme.colours.red};
  padding-top: 100px;
  padding-bottom: 10px;
  overflow: hidden;
  position: relative;

  @media (max-width: ${theme.screens.screenMedium}) {
    padding-top: 30px;
  };
`;

const Watermark = styled.img`
  position: absolute;
  top: -270px;
  right: 0;
  width: 1000px;
`;

const SubHeading = styled.h3`
  color: ${theme.colours.pink};
  font-family: ${theme.font.nimbleFont};
  font-weight: bold;
  font-size: 21px;

  @media (max-width: ${theme.screens.screenMedium}) {
    font-size: 19px;
  };
`;

const Heading = styled.h1`
  color: ${theme.colours.white};
  font-family: ${theme.font.nimbleHeadingFont};
  font-size: 90px;
  text-transform: uppercase;
  text-align: left !important;
  margin-top: 15px;
  line-height: 0.9;

  @media (max-width: ${theme.screens.screenMedium}) {
    font-size: 47px;
  };
`;

const Hero = ({ title }) => {
  // Dynamic Padding
  const [paddingLeft, setPaddingLeft] = useState(20);
  const { width } = useWindowDimensions();
  const desktopWidthMin = 1420;
  const paddingLeftStyle = {
    paddingLeft: `${paddingLeft}px`
  };
  useEffect(() => {
    const contentMaxWidth = 1440;
    if (width <= desktopWidthMin) {
      setPaddingLeft(20);
    } else {
      var leftPadding = Math.max(0, (width - contentMaxWidth) / 2 + 25);
    }
    setPaddingLeft(leftPadding);
  }, [width]);

  return (
    <HeroWrapper style={paddingLeftStyle}>
      <Watermark src={Rabbit} />
      {/* <SubHeading></SubHeading> */}
      <Heading>{title}</Heading>
    </HeroWrapper>
  );
};

export default Hero;
